<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">
    <div>
      <Breadcrumbs class="inlineblock" />
    </div>

    <v-container>
      <v-row align="center"
        v-if="canManageParameters"
        justify="center">
        <v-col md="8" sm="10" cols="12">
          <v-card class="align-center border10">
            <v-list>
              <v-subheader>{{ $t("general.settings") }}</v-subheader>

              <SettingsItem
                :url="'/settings/projecttypes'"
                :icon="'mdi-clipboard-list-outline'"
                :title="$t('general.projecttypes')"
              />

              <v-divider v-if="canManageNotifications" />

              <SettingsItem
                v-if="canManageNotifications"
                :url="'/settings/notificationManuals'"
                :icon="'mdi-bell'"
                :title="$t('general.notifications')"
              />

              <v-divider v-if="canManageVideos" />

              <SettingsItem
                v-if="canManageVideos"

                :url="'/settings/videos'"
                :icon="'mdi-youtube'"
                :title="$t('general.videos')"
              />

              <v-divider />

              <SettingsItem
                :url="'/settings/plates'"
                :icon="'mdi-wrench-outline'"
                :title="$t('general.plates')"
              />

              <v-divider />

              <SettingsItem
                :url="'/settings/groups'"
                :icon="'mdi-account-group-outline'"
                :title="$t('group.group_dealer')"
              />

              <v-divider v-if="canManageSecurity" />

              <SettingsItem
                v-if="canManageSecurity"

                :url="'/settings/security'"
                :icon="'mdi-account-key-outline'"
                :title="$t('user.security')"
              />
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import Breadcrumbs from "@/components/Breadcrumbs";
import SettingsItem from "@/components/menus/SettingsItem.vue";

export default {
  name: "Settings",
  components: {
    Breadcrumbs,
    SettingsItem,
  },
  data: () => {
      return {
         
      };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    ...mapGetters({
      canManageSecurity: 'canManageSecurity',
      canManageParameters: 'canManageParameters',
      canManageVideos: 'canManageVideos',
      canManageNotifications: 'canManageNotifications'
    }),
  },
  methods: {
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
    }),
  },
  mounted() {
    this.setBreadcrumbs([{ text: this.$t("general.settings"), href: "/settings" }]);
  },
};
</script>
<style scoped>
</style> 